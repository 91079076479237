// src/index.js
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './i18n'; // Инициализация i18n
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import { useSessionStore } from './sessionStore'; 
import ErrorBoundary from './components/ErrorBoundary'; // Убедитесь, что ErrorBoundary создан

// Функция для получения traffID из пути URL
function getTraffIdFromPath() {
  const pathSegments = window.location.pathname.split('/').filter(Boolean);
  // Предполагается, что traffID - это последний сегмент пути: mydomain.com/{traffID}
  const traffID = pathSegments.length > 0 ? pathSegments[pathSegments.length - 1] : '';
  return traffID;
}

// Генерация нового sessionID (примерная логика)
function generateSessionID() {
  return 'sess_' + Math.random().toString(36).substr(2, 9);
}

const Root = () => {
  const [initialized, setInitialized] = useState(false);

  // Достаем нужные методы и состояние из Zustand
  const setInitialState = useSessionStore(state => state.setInitialState);
  const updateSessionId = useSessionStore(state => state.updateSessionId);
  const updateSessionState = useSessionStore(state => state.updateSessionState);
  const updateUserInfoAndIP = useSessionStore(state => state.updateUserInfoAndIP);
  const sessionState = useSessionStore(state => state.sessionState);

  useEffect(() => {
    console.log('Root useEffect: Initializing session');

    // Шаг 1: Проверяем LocalStorage на наличие sessionData
    const savedSession = localStorage.getItem('sessionData');
    let sessionData;
    let traffID = getTraffIdFromPath();
    console.log('TraffID from path:', traffID);

    if (savedSession) {
      console.log('Found savedSession in localStorage');
      try {
        sessionData = JSON.parse(savedSession);
        console.log('Parsed sessionData:', sessionData);
      } catch (err) {
        console.error("Error parsing sessionData from localStorage:", err);
      }

      if (sessionData) {
        setInitialState(sessionData);
        console.log('Set initial state from savedSession');

        if (sessionData.sessionID) {
          updateSessionId(sessionData.sessionID);
          console.log('Set sessionID:', sessionData.sessionID);
        }

        // Если traffID не был установлен ранее, устанавливаем
        if (!sessionData.traffID && traffID) {
          updateSessionState({ traffID });
          sessionData.traffID = traffID;
          localStorage.setItem('sessionData', JSON.stringify(sessionData));
          console.log('Updated sessionData with traffID:', traffID);
        }

        setInitialized(true);
        console.log('Session initialized from savedSession');
      } else {
        // Если данные повреждены или некорректны
        console.log('Session data invalid, creating new session');
        const newSessionID = generateSessionID();
        updateSessionId(newSessionID);
        updateSessionState({ traffID });
        console.log('Generated new sessionID:', newSessionID);

        updateUserInfoAndIP().then(() => {
          const currentState = useSessionStore.getState().sessionState;
          const newSessionData = {
            ...currentState,
            sessionID: newSessionID,
            traffID: traffID
          };
          localStorage.setItem('sessionData', JSON.stringify(newSessionData));
          console.log('Saved new sessionData to localStorage:', newSessionData);
          setInitialized(true);
        }).catch(err => {
          console.error('Error in updateUserInfoAndIP:', err);
        });
      }
    } else {
      // Если в LocalStorage нет данных - создаем новую сессию
      console.log('No savedSession, creating new session');
      const newSessionID = generateSessionID();
      updateSessionId(newSessionID);
      updateSessionState({ traffID });
      console.log('Generated new sessionID:', newSessionID);

      updateUserInfoAndIP().then(() => {
        const currentState = useSessionStore.getState().sessionState;
        const newSessionData = {
          ...currentState,
          sessionID: newSessionID,
          traffID: traffID
        };

        localStorage.setItem('sessionData', JSON.stringify(newSessionData));
        console.log('Saved new sessionData to localStorage:', newSessionData);
        setInitialized(true);
      }).catch(err => {
        console.error('Error in updateUserInfoAndIP:', err);
      });
    }
  }, [setInitialState, updateSessionId, updateSessionState, updateUserInfoAndIP]);

  // После инициализации, определяем язык на основе ip.country
  useEffect(() => {
    console.log('Root useEffect: Setting language');
    if (!initialized) return;

    const country = sessionState?.ip?.country?.toLowerCase() || 'us';
    console.log('Country for language:', country);
    const countryLanguageMap = {
      'at': 'de',
      'hr': 'hr',
      'it': 'it',
      'pl': 'pl',
      'pt': 'pt',
      'ba': 'ba',
      'bg': 'bg',
      'es': 'es',
      'de': 'de',
      'ch': 'de',
      'tr': 'tr',
      'hu': 'hu',
      'sk': 'sk',
      'fr': 'fr',
      'us': 'en',
      'gb': 'en',
    };

    const language = countryLanguageMap[country] || 'en';
    console.log('Language to set:', language);
    if (i18n.language !== language) {
      i18n.changeLanguage(language);
      console.log(`Language changed to ${language}`);
    }
  }, [initialized, sessionState]);

  // Пока инициализация не завершена, показываем загрузку
  if (!initialized) {
    console.log('Rendering Loading...');
    return <div>Loading...</div>;
  }

  // После инициализации рендерим приложение
  console.log('Rendering App');
  return (
    <I18nextProvider i18n={i18n}>
      <ErrorBoundary>
        <App style={{ overflowX: 'hidden' }} />
      </ErrorBoundary>
    </I18nextProvider>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<Root />);
